// Imports
import { UserContextProvider } from "./frontend/context_providers/UserContext.jsx";
import MainRouter from "./frontend/routing/MainRouter.jsx";
import ReactGA from "react-ga4";
import { trackPage } from "./frontend/google_analytics/ga-tracking.js";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";
import Ga4CookieConsent from "./frontend/google_analytics/Ga4CookieConsent.jsx";
import { meta } from "./frontend/utils/config/meta.js";
import { page_links } from "./frontend/utils/config/page-links.js";
import { getTextAfterLastSlash } from "./frontend/utils/general/text-formatting.js";
import { projects_data } from "./frontend/utils/config/projects.js";
import { vacancies_data } from "./frontend/utils/config/vacancies.js";
import { blog_data } from "./frontend/utils/config/blog.js";

//Define the App component
export default function App() {
  const location = useLocation();

  useEffect(() => {
    const path = location.pathname;
    let metaDescription = meta[location.pathname]?.description;
    let metaTitle = meta[location.pathname]?.title;

    if (metaDescription === undefined) {
      if (path.startsWith(`${page_links.project.link}/`) && path !== page_links.project.link) {
        const projectData = projects_data[getTextAfterLastSlash(path)];
        metaDescription = `See our project ${projectData.given_name || projectData.project_name} in ${
          projectData.address.county
        }, ${projectData.address.country}. The project is currently in the ${projectData.stage} stage and consists of ${
          projectData.units
        } units. We are working with ${projectData.housing_association} on this project.`;
        metaTitle = `${projectData.given_name || projectData.project_name} | Castell Group`;
      } else if (path.startsWith(`${page_links.vacancy.link}/`) && path !== page_links.vacancy.link) {
        const vacancyData = vacancies_data[getTextAfterLastSlash(path)];
        metaDescription = vacancyData.role_overview;
        metaTitle = `${vacancyData.title} | Castell Group`;
      } else if (path.startsWith(`${page_links.blog.link}/`) && path !== page_links.blog.link) {
        const blogData = blog_data[getTextAfterLastSlash(path)];
        metaDescription = blogData.overview;
        metaTitle = `${blogData.title} | Castell Group`;
      } else if (path.startsWith(`/information/`)) {
        const projectInformation = projects_data[getTextAfterLastSlash(path).toUpperCase()];
        metaDescription = projectInformation.overview;
        metaTitle = `${
          projectInformation.given_name || projectInformation.project_name
        } Project Information | Castell Group`;
      } else {
        metaTitle = "404 Not Found | Castell Group";
        metaDescription = "The page you are looking for does not exist. Please check the URL and try again.";
      }
    }

    document.querySelector('meta[name="description"]').setAttribute("content", metaDescription);
    document.title = metaTitle;

    trackPage(ReactGA, location);
  }, [location]);

  return (
    <UserContextProvider>
      <MainRouter />
      <Ga4CookieConsent /> {/* Show the cookie consent banner */}
    </UserContextProvider>
  );
}
