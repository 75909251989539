import { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { UserContext } from "../../context_providers/UserContext.jsx";
import { header_top_socials, header_top_contacts } from "../../utils/config/config.js";
import { handleScaleInlineTailwind300 } from "../../utils/animations/animations.js";
import { ReactComponent as Hamburger } from "../../utils/svg_components/hamburger.svg";
import { header_bottom_logo } from "../../utils/config/image-links.js";
import { insertTextbeforeUrlExtention } from "../../utils/general/text-formatting.js";
import { res_header_bottom_logo } from "../../utils/config/image-resolutions.js";
// import DynamicLink from "../../components/DynamicLink.jsx";
import ReactGA from "react-ga4";

const pages = [
  {
    id: "overview",
    text: "Project Overview",
  },
  {
    id: "photos",
    text: "Photos",
  },
  {
    id: "milestones",
    text: "Key Milestones",
  },
  {
    id: "faq",
    text: "FAQ",
  },
];

function HeaderTop({ isHeaderBottomHidden }) {
  const [hasHeaderBottomBeenHidden, setHasHeaderBottomBeenHidden] = useState(false);

  useEffect(() => {
    if (isHeaderBottomHidden) {
      setHasHeaderBottomBeenHidden(true);
    }
  }, [isHeaderBottomHidden]);

  return (
    <>
      <div className="flex w-full h-16 bg-castell items-center lg:justify-between justify-center px-[5%] gap-4 z-20 relative">
        <div className="flex flex-row gap-8">
          {header_top_socials.map((social) => (
            <a
              aria-label={`Visit Castell on ${social.id}`}
              key={social.id}
              href={social.link}
              target="_blank"
              rel="noopener noreferrer"
              onMouseEnter={(e) => e.currentTarget.classList.add("scale-125")}
              onMouseLeave={(e) => e.currentTarget.classList.remove("scale-125")}
              className={`${handleScaleInlineTailwind300}`}
              onClick={() =>
                ReactGA.event({
                  category: social.ga4_event.category,
                  action: social.ga4_event.action,
                  label: social.ga4_event.label,
                })
              }
            >
              <social.icon strokeWidth="0.9" className={`w-8 fill-white`} />
            </a>
          ))}
        </div>

        <div className={`hidden lg:flex flex-col md:flex-row md:gap-8 items-center gap-2`}>
          {header_top_contacts.map((item) => (
            <a
              aria_label={`Contact Castell on ${item.text}`}
              key={item.id}
              href={item.link}
              onMouseEnter={(e) => e.currentTarget.classList.add("scale-110")}
              onMouseLeave={(e) => e.currentTarget.classList.remove("scale-110")}
              onClick={() =>
                ReactGA.event({
                  category: item.ga4_event.category,
                  action: item.ga4_event.action,
                  label: item.ga4_event.label,
                })
              }
              className={`text-white flex items-center gap-2 ${handleScaleInlineTailwind300}`}
            >
              <item.icon strokeWidth="1.2" className="w-6 stroke-castell fill-white" />
              <span>{item.text}</span>
            </a>
          ))}
        </div>
      </div>
      <div
        className={`absolute w-full ${
          isHeaderBottomHidden ? "animate-slideDown" : hasHeaderBottomBeenHidden ? "animate-slideUp" : "hidden"
        }`}
      >
        <HeaderBottomSmall hasHeaderBottomBeenHidden={hasHeaderBottomBeenHidden} />
      </div>
    </>
  );
}

function HeaderBottom(hasHeaderBottomBeenHidden) {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const { projectInformationPage, setProjectInformationPage, setSelectedMilestone } = useContext(UserContext);

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const windowThreshold = 1000; // Set your desired threshold width in pixels

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    hasHeaderBottomBeenHidden && setIsDropdownOpen(false);
  }, [hasHeaderBottomBeenHidden]);

  return (
    <div className="relative w-full border-b border-gray-200">
      <div
        className={`flex flex-row w-full h-20 md:h-24 lg:28 xl:32 bg-white items-center ${
          windowWidth > windowThreshold ? "justify-center px-[5%]" : "justify-between px-[20%]"
        } gap-10 xl:gap-20`}
      >
        <Link
          aria-label="Navigate to Home Page"
          to={"/"}
          onMouseEnter={(e) => e.currentTarget.classList.add("scale-110")}
          onMouseLeave={(e) => e.currentTarget.classList.remove("scale-110")}
          className={`flex h-full ${handleScaleInlineTailwind300}`}
        >
          <img
            src={insertTextbeforeUrlExtention(header_bottom_logo, res_header_bottom_logo)}
            alt="Castell Logo"
            className="h-full py-4"
          />
        </Link>

        {windowWidth > windowThreshold ? (
          <div className="flex flex-col justify-center sm:flex-row gap-6 lg:gap-8 text-xs xl:text-sm 2xl:text-base 3xl:text-lg text-castell font-semibold mt-2 sm:mt-0 bg-white">
            {pages.map((page) => (
              <button
                key={page.id}
                className={`"flex px-8 py-1 text-center rounded-sm border ${
                  projectInformationPage.id === page.id
                    ? "bg-castell text-white border-castell"
                    : `${handleScaleInlineTailwind300} hover:border-orangemain border-gray-400 hover:text-orangemain`
                }`}
                onMouseEnter={(e) => {
                  projectInformationPage.id !== page.id && e.currentTarget.classList.add("scale-105");
                }}
                onMouseLeave={(e) => {
                  projectInformationPage.id !== page.id && e.currentTarget.classList.remove("scale-105");
                }}
                onClick={() => {
                  setProjectInformationPage(page);
                  page.id === "milestones" && setSelectedMilestone({});
                }}
              >
                {page.text}
              </button>
            ))}
          </div>
        ) : (
          <>
            <button
              aria-label="Open page links"
              onClick={() => setIsDropdownOpen(!isDropdownOpen)}
              onMouseEnter={(e) => e.currentTarget.classList.add("scale-110")}
              onMouseLeave={(e) => e.currentTarget.classList.remove("scale-110")}
              className={`flex justify-center text-castell font-semibold items-center text-base ${handleScaleInlineTailwind300}`}
            >
              <Hamburger strokeWidth="1.2" className="w-6 stroke-castell fill-white" />
              <div className="flex ml-4">Page Links</div>
            </button>
            {isDropdownOpen && (
              <div className="absolute top-full left-0 w-full bg-white z-50 border border-gray-200">
                <div className="flex flex-col text-sm text-castell font-semibold p-1">
                  {pages.map((page) => (
                    <button
                      key={page.id}
                      className={`"flex px-8 py-1 text-center rounded-sm ${
                        projectInformationPage.id === page.id
                          ? "bg-castell text-white"
                          : `${handleScaleInlineTailwind300} hover:text-orangemain`
                      }`}
                      onMouseEnter={(e) => {
                        projectInformationPage.id !== page.id && e.currentTarget.classList.add("scale-105");
                      }}
                      onMouseLeave={(e) => {
                        projectInformationPage.id !== page.id && e.currentTarget.classList.remove("scale-105");
                      }}
                      onClick={() => {
                        setProjectInformationPage(page);
                        page.id === "milestones" && setSelectedMilestone({});
                      }}
                    >
                      {page.text}
                    </button>
                  ))}
                </div>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
}

function HeaderBottomSmall(hasHeaderBottomBeenHidden) {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const windowThreshold = 800; // Set your desired threshold width in pixels
  const { projectInformationPage, setProjectInformationPage, setSelectedMilestone } = useContext(UserContext);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    hasHeaderBottomBeenHidden && setIsDropdownOpen(false);
  }, [hasHeaderBottomBeenHidden]);

  return (
    <div className="flex flex-row w-full bg-white gap-6 lg:gap-8 items-center justify-center px-[10%] text-xs lg:text-sm xl:text-base 2xl:text-lg text-castell font-semibold py-2 border-b border-gray-200">
      {windowWidth > windowThreshold ? (
        <>
          {pages.map((page) => (
            <button
              key={page.id}
              className={`"flex px-8 py-1 text-center rounded-sm border ${
                projectInformationPage.id === page.id
                  ? "bg-castell text-white border-castell"
                  : `${handleScaleInlineTailwind300} hover:border-orangemain border-gray-400 hover:text-orangemain`
              }`}
              onMouseEnter={(e) => {
                projectInformationPage.id !== page.id && e.currentTarget.classList.add("scale-105");
              }}
              onMouseLeave={(e) => {
                projectInformationPage.id !== page.id && e.currentTarget.classList.remove("scale-105");
              }}
              onClick={() => {
                setProjectInformationPage(page);
                page.id === "milestones" && setSelectedMilestone({});
              }}
            >
              {page.text}
            </button>
          ))}
        </>
      ) : (
        <>
          <button
            aria-label="Open page links"
            onClick={() => setIsDropdownOpen(!isDropdownOpen)}
            onMouseEnter={(e) => e.currentTarget.classList.add("scale-110")}
            onMouseLeave={(e) => e.currentTarget.classList.remove("scale-110")}
            className={`flex justify-center text-castell font-semibold items-center text-base ${handleScaleInlineTailwind300}`}
          >
            <Hamburger strokeWidth="1.2" className="w-6 stroke-castell fill-white" />
            <div className="flex ml-4">Page Links</div>
          </button>
          {isDropdownOpen && (
            <div className="absolute top-full left-0 w-full bg-white z-50">
              <div className="flex flex-col text-sm text-castell font-semibold p-1">
                {pages.map((page) => (
                  <button
                    key={page.id}
                    className={`"flex px-8 py-1 text-center rounded-sm ${
                      projectInformationPage.id === page.id
                        ? "bg-castell text-white"
                        : `${handleScaleInlineTailwind300} hover:text-orangemain`
                    }`}
                    onMouseEnter={(e) => {
                      projectInformationPage.id !== page.id && e.currentTarget.classList.add("scale-105");
                    }}
                    onMouseLeave={(e) => {
                      projectInformationPage.id !== page.id && e.currentTarget.classList.remove("scale-105");
                    }}
                    onClick={() => {
                      setProjectInformationPage(page);
                      page.id === "milestones" && setSelectedMilestone({});
                    }}
                  >
                    {page.text}
                  </button>
                ))}
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
}

export { HeaderTop, HeaderBottom, HeaderBottomSmall };
