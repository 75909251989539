import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import { UserContext } from "../../context_providers/UserContext";
import { getTextAfterLastSlash, insertTextbeforeUrlExtention } from "../../utils/general/text-formatting";
import { template_image_dark, template_image_light } from "../../utils/config/image-links";
import { project_information_photo_blob } from "../../utils/config/config.js";
import {
  handle_bullet_points,
  handle_text_block,
  handle_image,
  handle_headings,
} from "../../utils/functions/blog_functions.js";
import { convertYyyymmddToWordyDate } from "../../utils/general/date-conversions.js";
import { project_information_data } from "../../utils/config/project-information.js";
import { projects_data } from "../../utils/config/projects.js";
import { getProjectPhotoUrls } from "./functions.js";
import { handleMouseLeave, handleMouseMove } from "../../utils/animations/tilt-animation-functions.js";
import { ImageLoader } from "../../components/ImageLoaders.jsx";
import { res_types } from "../../utils/config/image-resolutions.js";
import ImageCarousel from "../../components/ImageCarousel.jsx";
import HubspotForm from "../../components/HubspotForm.jsx";
import { frm_newsletter } from "../../utils/config/hubspot_forms.js";

export default function ProjectInformation() {
  const { location, selectedMilestone, projectInformationPage, setSelectedMilestone } = useContext(UserContext);
  const projectId = getTextAfterLastSlash(location.pathname);
  const [photoUrlsObject, setPhotoUrlsObject] = useState({});

  const buttonRefs = useRef([]);
  const throttledMouseMove = useRef(handleMouseMove(buttonRefs));
  const handleMouseMoveCallback = useCallback(throttledMouseMove.current, []); // eslint-disable-line react-hooks/exhaustive-deps
  const handleMouseLeaveCallback = useCallback(handleMouseLeave(buttonRefs, throttledMouseMove.current), []); // eslint-disable-line react-hooks/exhaustive-deps
  const projectInformationObject = project_information_data[projectId] || {};
  const projectDataObject = projects_data[projectId.toUpperCase()] || {};

  const [hoveredImage, setHoveredImage] = useState(null);
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
  const [imageSize, setImageSize] = useState({ width: 600, height: 400 });

  useEffect(() => {
    getProjectPhotoUrls(projectId, setPhotoUrlsObject, project_information_photo_blob, res_types[2]);
  }, [projectId]);

  const handlePreviewMouseEnter = (url, e) => {
    const extension = url.split(".").pop();
    const url_split = url.split("/");
    const file_split = url_split[url_split.length - 1].split("_");
    url_split[url_split.length - 1] = file_split[0] + res_types[3] + "." + extension;
    const newurl = url_split.join("/");

    // Create an image object to get actual dimensions
    const img = new Image();
    img.onload = () => {
      // Define maximum dimensions
      const maxWidth = 1000;
      const maxHeight = 600;

      // Calculate aspect ratio
      const aspectRatio = img.width / img.height;

      // Initial calculations
      let width = img.width;
      let height = img.height;

      // If image exceeds either max dimension, scale it down
      if (width > maxWidth || height > maxHeight) {
        // Check if width is the limiting factor
        if (width / maxWidth > height / maxHeight) {
          // Scale based on width
          width = maxWidth;
          height = width / aspectRatio;
        } else {
          // Scale based on height
          height = maxHeight;
          width = height * aspectRatio;
        }
      }

      // Ensure final dimensions don't exceed maximums (safety check)
      width = Math.min(width, maxWidth);
      height = Math.min(height, maxHeight);

      setImageSize({
        width: Math.round(width),
        height: Math.round(height),
      });
    };
    img.src = newurl;

    setHoveredImage(newurl);
    setMousePosition({ x: e.clientX, y: e.clientY });
  };

  const handlePreviewMouseLeave = () => {
    setHoveredImage(null);
  };

  const handlePreviewMouseMove = (e) => {
    setMousePosition({ x: e.clientX, y: e.clientY });
  };

  return (
    <React.Fragment>
      <ImageCarousel
        urls={
          Array.isArray(projectDataObject.cgi_urls) && projectDataObject.cgi_urls.length > 0
            ? projectDataObject.cgi_urls
            : [template_image_dark]
        }
        text={[projectDataObject.given_name || projectDataObject.project_name]}
        className="relative w-full h-40 lg:h-64 xl:h-96 overflow-hidden flex items-center justify-center bg-white"
      />
      <div className="py-16 px-[5%] md:px-[15%]">
        <div className="flex flex flex-col flex-grow rounded-2xl border border-gray-100 shadow-2xl bg-white min-h-256 overflow-hidden">
          {/* OVERVIEW */}
          {projectInformationPage.id === "overview" && (
            <React.Fragment>
              {projectInformationObject.overview ? (
                <div className="flex flex-col px-[10%] md:px-[20%] py-32 bg-white text-black gap-12 text-base lg:text-lg">
                  {projectInformationObject.overview.sections.map((section, index) => (
                    <div key={index} className="flex flex-col">
                      {section.map((sub_section, index) => (
                        <div key={index}>
                          {sub_section.headings
                            ? handle_headings(sub_section.headings)
                            : sub_section.image
                            ? handle_image(sub_section.image)
                            : sub_section.text_block
                            ? handle_text_block(sub_section.text_block)
                            : sub_section.bullet_points && handle_bullet_points(sub_section.bullet_points)}
                        </div>
                      ))}
                    </div>
                  ))}
                </div>
              ) : (
                <div className="flex w-full h-32 items-center justify-center text-3xl font-semibold text-gray-700">
                  Project Overview Coming Soon...
                </div>
              )}
            </React.Fragment>
          )}

          {/* PHOTOS */}
          {projectInformationPage.id === "photos" && (
            <React.Fragment>
              {Object.keys(photoUrlsObject).length > 0 ? (
                <div className="flex flex-col w-full font-semibold gap-4 p-8 py-12 md:px-16">
                  {Object.entries(photoUrlsObject)
                    .sort((a, b) => parseInt(b[0]) - parseInt(a[0]))
                    .map(([year, monthObject]) => (
                      <div key={year}>
                        {Object.entries(monthObject).map(([month, urls]) => (
                          <React.Fragment key={month}>
                            <h3 className="text-xl text-gray-700 bg-gray-100 p-2 lg:pl-8 rounded-md text-center lg:text-left ">
                              {month} - {year}
                            </h3>
                            <div className="flex flex-wrap gap-8 mb-8 justify-center pb-8 pt-2">
                              {urls.map((url, i) => (
                                <div
                                  key={i}
                                  onMouseEnter={(e) => handlePreviewMouseEnter(url, e)}
                                  onMouseLeave={handlePreviewMouseLeave}
                                  onMouseMove={handlePreviewMouseMove}
                                >
                                  <ImageLoader
                                    src={url}
                                    alt={`Project ${projectId}: ${month}-${year}`}
                                    containerClass="flex"
                                    imageClass="border border-gray-400 shadow-xl rounded-lg h-64 w-auto"
                                  />
                                </div>
                              ))}
                            </div>
                          </React.Fragment>
                        ))}
                      </div>
                    ))}

                  {/* Preview Div */}
                  {hoveredImage && (
                    <div
                      className="lg:block hidden fixed pointer-events-none z-50"
                      style={{
                        left:
                          mousePosition.x > window.innerWidth / 2
                            ? mousePosition.x - imageSize.width - 20
                            : mousePosition.x + 20,
                        top: Math.max(20, mousePosition.y - imageSize.height / 1.5), // Center vertically, keep in viewport
                        width: `${imageSize.width}px`,
                        height: `${imageSize.height}px`,
                      }}
                    >
                      <ImageLoader
                        src={hoveredImage}
                        alt="Preview Image"
                        containerClass="w-full h-full bg-white border border-black shadow-xl rounded-lg overflow-hidden"
                        imageClass="w-full h-full object-contain"
                      />
                    </div>
                  )}
                </div>
              ) : (
                <div className="flex w-full h-32 items-center justify-center text-3xl font-semibold text-gray-700">
                  Photos Coming Soon...
                </div>
              )}
            </React.Fragment>
          )}

          {/* MILESTONES */}
          {projectInformationPage.id === "milestones" && (
            <React.Fragment>
              {projectInformationObject.milestones ? (
                <div className="flex flex-col">
                  {selectedMilestone.id ? (
                    <React.Fragment>
                      <div className="flex flex-col px-8  md:px-[20%] py-8 md:py-32 bg-white text-black gap-12 text-base lg:text-lg">
                        {selectedMilestone.id && (
                          <div className="flex flex-col gap-8 w-full justify-center items-center">
                            <div className="flex flex-col gap-4">
                              {selectedMilestone.title && (
                                <div className="text-3xl lg:text-4xl font-semibold">{selectedMilestone.title}</div>
                              )}
                              {/* {selectedMilestone.date && (
                                <div className="text-lg lg:text-xl">
                                  {convertYyyymmddToWordyDate(selectedMilestone.date)}
                                </div>
                              )} */}
                            </div>
                            <div className="flex flex-col gap-8">
                              {selectedMilestone.sections.map((section, index) => (
                                <div key={index} className="flex flex-col">
                                  {section.map((sub_section, index) => (
                                    <div key={index}>
                                      {sub_section.headings
                                        ? handle_headings(sub_section.headings)
                                        : sub_section.image
                                        ? handle_image(sub_section.image, "h-48 md:h-96")
                                        : sub_section.text_block
                                        ? handle_text_block(sub_section.text_block)
                                        : sub_section.bullet_points && handle_bullet_points(sub_section.bullet_points)}
                                    </div>
                                  ))}
                                </div>
                              ))}
                            </div>
                          </div>
                        )}
                      </div>
                    </React.Fragment>
                  ) : (
                    <div className="grid grid-cols-1 lg:grid-cols-2 2xl:grid-cols-3 w-full p-8 md:px-[10%] md:py-24 gap-8  bg-white">
                      {Object.values(projectInformationObject.milestones)
                        .filter((m) => m.active)
                        .map((milestone, index) => (
                          <button
                            aria-label={`View ${milestone.title}`}
                            key={index}
                            ref={(el) => (buttonRefs.current[index] = el)}
                            onClick={() => setSelectedMilestone(milestone)}
                            className="flex flex-col w-full rounded-lg overflow-hidden transition-transform duration-150 border border-gray-200 shadow-xl"
                            onMouseMove={(e) => handleMouseMoveCallback(e, index)}
                            onMouseLeave={() => handleMouseLeaveCallback(index)}
                          >
                            <ImageLoader
                              src={
                                milestone.image.image_url !== ""
                                  ? milestone.image.image_url
                                  : insertTextbeforeUrlExtention(template_image_light, res_types[1])
                              }
                              alt={milestone.image.image_alt}
                              containerClass="flex w-full h-64"
                              imageClass={`w-full h-64 object-cover ${milestone.image.top && "object-top"} ${
                                milestone.image.bottom && "object-bottom"
                              }`}
                            />
                            <div className="flex flex-col w-full h-64 p-8 gap-4 text-left border-t border-gray-200">
                              <div className="text-lg font-semibold">{milestone.title}</div>
                              <div className="text-sm text-gray-700">{milestone.overview}</div>
                            </div>
                            {/* <div className="flex w-full py-4 px-8 border-t text-sm border-gray-200 text-gray-500">
                              {convertYyyymmddToWordyDate(milestone.date)}
                            </div> */}
                          </button>
                        ))}
                    </div>
                  )}
                </div>
              ) : (
                <div className="flex w-full h-32 items-center justify-center text-3xl font-semibold text-gray-700">
                  Milestones Coming Soon...
                </div>
              )}
            </React.Fragment>
          )}

          {/* FAQ */}
          {projectInformationPage.id === "faq" && (
            <div className="flex flex-col w-full">
              {projectInformationObject.faq ? (
                <div className="flex flex-col px-8 md:px-[20%] py-8 md:py-32 bg-white text-black gap-12 text-base lg:text-lg">
                  {projectInformationObject.faq.sections.map((section, index) => (
                    <div key={index} className="flex flex-col">
                      {section.map((sub_section, index) => (
                        <div key={index}>
                          {sub_section.headings
                            ? handle_headings(sub_section.headings)
                            : sub_section.image
                            ? handle_image(sub_section.image)
                            : sub_section.text_block
                            ? handle_text_block(sub_section.text_block)
                            : sub_section.bullet_points && handle_bullet_points(sub_section.bullet_points)}
                        </div>
                      ))}
                    </div>
                  ))}
                </div>
              ) : (
                <div className="flex w-full h-32 items-center justify-center text-3xl font-semibold text-gray-700">
                  FAQ Coming Soon...
                </div>
              )}
            </div>
          )}

          {/* CONTACT US */}
          <div className="flex flex-col px-[10%] md:px-[20%] py-16 bg-white text-black gap-12 text-base lg:text-lg">
            <div className="flex flex-col gap-4">
              <div className="text-3xl font-semibold">Have a question about this development?</div>
              <div className="text-lg">
                Please use the form below to get in touch with us for any additional information or specific inquiries.
                Remember to include a subject line like
                <span className="font-semibold">
                  "{projectDataObject.given_name.toUpperCase() || projectDataObject.project_name.toUpperCase()} RESIDENT
                  QUERY"
                </span>{" "}
                so we can direct your query appropriately.
              </div>
            </div>
            <HubspotForm
              region={frm_newsletter.region}
              portalId={frm_newsletter.portalId}
              formId={frm_newsletter.formId}
            />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
