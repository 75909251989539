import { useContext, useEffect, useRef, useState } from "react";
import { Outlet } from "react-router-dom";
import { HeaderBottom, HeaderTop } from "./ProjectInformationHeader.jsx";
import { FooterBottom, FooterTop } from "./ProjectInformationFooter.jsx";
import { layout_cover_image } from "../../utils/config/site_urls.js";
import { insertTextbeforeUrlExtention } from "../../utils/general/text-formatting.js";
import { res_layout_background_image } from "../../utils/config/image-resolutions.js";
import BlogSidebar from "../../components/BlogSidebar.jsx";
import VacancySidebar from "../../components/VacancySidebar.jsx";
import { UserContext } from "../../context_providers/UserContext.jsx";
import { castellRegex } from "../../utils/general/regex.js";
import { project_information_data } from "../../utils/config/project-information.js";

export default function Layout() {
  const [isHeaderBottomHidden, setIsHeaderBottomHidden] = useState(false);
  const { location } = useContext(UserContext);
  const headerBottomRef = useRef(null);
  const projectId = location.pathname.split("/").pop();

  useEffect(() => {
    const currentHeaderBottomRef = headerBottomRef.current;

    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsHeaderBottomHidden(!entry.isIntersecting);
      },
      { threshold: 0 }
    );

    if (currentHeaderBottomRef) {
      observer.observe(currentHeaderBottomRef);
    }

    return () => {
      if (currentHeaderBottomRef) {
        observer.unobserve(currentHeaderBottomRef);
      }
    };
  }, []);

  if (!projectId || !castellRegex.projectCodeLcase.test(projectId) || !project_information_data[projectId]) {
    window.location.href = "/not-found";
    return null; // Prevent rendering while redirecting
  }

  return (
    <div
      className="relative flex flex-col min-h-screen"
      style={{
        backgroundImage: `url(${insertTextbeforeUrlExtention(layout_cover_image, res_layout_background_image)})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundAttachment: "fixed",
      }}
    >
      <div className="fixed top-0 w-full z-50">
        <HeaderTop isHeaderBottomHidden={isHeaderBottomHidden} />
      </div>

      {/* Scrollable Content */}
      <div className="mt-16 flex-1 flex flex-col">
        <div ref={headerBottomRef}>
          <HeaderBottom isHeaderBottomHidden={isHeaderBottomHidden} />
        </div>
        <div className="flex-1 overflow-y-auto bg-white">
          <Outlet />
        </div>
      </div>

      {/* Footer always at the bottom */}
      <footer className="w-full">
        <FooterTop />
        <FooterBottom />
      </footer>

      <BlogSidebar />
      <VacancySidebar />
    </div>
  );
}
