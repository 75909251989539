// Imports
import { createContext, useEffect, useState } from "react";
import Cookies from "js-cookie";
import { useLocation } from "react-router-dom";
import { vacancies_data } from "../utils/config/vacancies";
import { department_data } from "../utils/config/staff";

// User Context Provider
export const UserContext = createContext({});
export function UserContextProvider({ children }) {
  const location = useLocation();

  const [selectedBlogs, setSelectedBlogs] = useState([]);

  const [selectedVacancyDepartment, setSelectedVacancyDepartment] = useState(() => {
    const cookieValue = Cookies.get("selectedVacancyDepartment");
    try {
      return cookieValue ? JSON.parse(cookieValue) : { id: "all", name: "All Vacancies" };
    } catch (error) {
      console.error("Failed to parse selectedVacancyDepartment cookie:", error);
      // Reset to default and clear invalid cookie
      Cookies.set("selectedVacancyDepartment", JSON.stringify({ id: "all", name: "All Vacancies" }));
      return { id: "all", name: "All Vacancies" };
    }
  });

  const [projectInformationPage, setProjectInformationPage] = useState(() => {
    const cookieValue = Cookies.get("projectInformationPage");
    try {
      return cookieValue ? JSON.parse(cookieValue) : { id: "overview", text: "Project Overview" };
    } catch (error) {
      console.error("Failed to parse projectInformationPage cookie:", error);
      // Reset to default and clear invalid cookie
      Cookies.set("projectInformationPage", JSON.stringify({ id: "overview", text: "Project Overview" }));
      return { id: "overview", text: "Project Overview" };
    }
  });

  const [selectedMilestone, setSelectedMilestone] = useState(() => {
    const cookieValue = Cookies.get("selectedMilestone");
    try {
      return cookieValue ? JSON.parse(cookieValue) : {};
    } catch (error) {
      console.error("Failed to parse selectedMilestone cookie:", error);
      return {};
    }
  });

  const [vacancyDepartments, setVacancyDepartments] = useState([
    { id: "all", name: "All Vacancies" },
    ...Object.values(department_data).filter((department) =>
      Object.values(vacancies_data)
        .map((vacancy) => vacancy.active === true && vacancy.department.id)
        .includes(department.id)
    ),
  ]);

  useEffect(() => {
    Cookies.set("projectInformationPage", JSON.stringify(projectInformationPage), { expires: 7 });
  }, [projectInformationPage]);

  useEffect(() => {
    Cookies.set("selectedVacancyDepartment", JSON.stringify(selectedVacancyDepartment), { expires: 7 });
  }, [selectedVacancyDepartment]);

  useEffect(() => {
    Cookies.set("selectedMilestone", JSON.stringify(selectedMilestone), { expires: 7 });
  }, [selectedMilestone]);

  return (
    <UserContext.Provider
      value={{
        location,
        selectedBlogs,
        setSelectedBlogs,
        vacancyDepartments,
        setVacancyDepartments,
        projectInformationPage,
        setProjectInformationPage,
        selectedVacancyDepartment,
        setSelectedVacancyDepartment,
        selectedMilestone,
        setSelectedMilestone,
      }}
    >
      {children}
    </UserContext.Provider>
  );
}
