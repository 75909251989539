import { ContainerClient } from "@azure/storage-blob";

export async function listPublicBlobs(blob_prefix) {
  const containerClient = new ContainerClient(blob_prefix);
  let blobUrls = [];
  for await (const blob of containerClient.listBlobsFlat()) {
    blobUrls.push(`${blob_prefix}${blob.name}`);
  }
  return blobUrls;
}

export const getProjectPhotoUrls = async (projectId, setPhotoUrlsObject, blobUrl, res_type) => {
  const allPhotoUrls = await listPublicBlobs(blobUrl);
  const filteredUrls = allPhotoUrls.filter((url) => url.includes(projectId) && url.includes(res_type));
  const result = {};

  for (const url of filteredUrls) {
    const fileName = url.split("/").pop(); // e.g. "c037-24-08-01.jpg"

    const [, year, month] = fileName.split(".")[0].split("-"); // Remove the file extension and split on the dash
    const fullYear = `20${year}`; // Convert "21" to "2021"
    const monthName = new Date(`${fullYear}-${month}-01`).toLocaleString("default", { month: "long" });

    if (!result[fullYear]) {
      result[fullYear] = {};
    }

    if (!result[fullYear][monthName]) {
      result[fullYear][monthName] = []; // Ensure the month array exists
    }

    result[fullYear][monthName].push(url); // Push the current URL into the appropriate year/month array
  }

  setPhotoUrlsObject(result);
};
